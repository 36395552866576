<!--ExpertPurchaseDetails 专家购跟进-->
<template>
  <div class="container">
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <el-button class="itemBtn btnColor" @click="kehugenzong"
          >代购追踪</el-button>
      </div>
      <div class="singleinpbtn wtl_queryBoxNew">
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询</el-button
        >
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">
            {{ MoreConditions }}
          </div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp wtlTimeBox">
            <div class="singleinpleft wtlTimeLabel">创建时间:</div>
            <div class="wtl_timeStyle">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.createStartTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
              <div>至</div>
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.createEndTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">工单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.expertBuyNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.expertbuyStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in orderTypeList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-select
                class="inputs"
                @change="huiayu($event, 1)"
                v-model="queryData.memberId"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembers"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in memberList"
                  :label="item.memberId"
                  :value="item.memberId + ''"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-select
                class="inputs"
                @change="huiayu($event, 2)"
                v-model="queryData.memberName"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembersName"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in memberList"
                  :label="item.nickname"
                  :value="item.nickname + ''"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">代购订单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.dgOrderNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">归属代理商:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.memberAgentId"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in orderFormList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- <div class="item_left">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div> -->
      </div>
    </el-form>
    <div class="contentInner">
      <!-- 左侧列表 -->
      <div class="rightInner">
        <el-table
          :data="expressData"
          border
          ref="mytable"
          @row-dblclick="dblclick"
          :row-class-name="tableRowClassName"
          highlight-current-row
          stripe
          size="small"
          :height="tableHeigth"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            label="专家购工单号"
            width="149"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="双击查看详情"
                placement="top-start"
              >
                <div>
                  {{ scope.row.expertBuyNumber }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          layout="total,prev, next"
          prev-text="上一页"
          next-text="下一页"
          :current-page="pageStart"
          :total="pageCount"
          :page-size="pageTotal"
          @size-change="psc"
          @current-change="pcc"
        >
        </el-pagination>
      </div>
      <!-- 右边信息 -->
      <div class="leftInner">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClickTabs"
          type="card"
          stretch
        >
          <el-tab-pane label="基本信息" name="first">
            <div>
              <el-form
                ref="form"
                class="baseForm"
                :model="baseInfo"
                label-width="100px"
              >
                <el-form-item class="formItem" label="工单号">
                  <el-input
                    readonly
                    v-model="baseInfo.expertBuyNumber"
                  ></el-input>
                </el-form-item>
                <el-form-item label="会员号">
                  <el-input readonly v-model="baseInfo.memberId"></el-input>
                </el-form-item>
                <el-form-item label="会员名称">
                  <el-input readonly v-model="baseInfo.memberName"></el-input>
                </el-form-item>
                <el-form-item label="归属代理商">
                  <el-input readonly v-model="baseInfo.memberAgent"></el-input>
                </el-form-item>
                <el-form-item label="创建时间">
                  <el-input readonly v-model="baseInfo.createTime"></el-input>
                </el-form-item>
                <el-form-item label="创建人">
                  <el-input
                    readonly
                    v-model="baseInfo.createUserName"
                  ></el-input>
                </el-form-item>
                <el-form-item label="状态">
                  <el-input
                    readonly
                    v-model="baseInfo.expertbuyStatusShow"
                  ></el-input>
                </el-form-item>
                <el-form-item label="代购单号">
                  <el-input
                    readonly
                    v-model="baseInfo.dgOrderNumber"
                  ></el-input>
                </el-form-item>
                <el-form-item label="数量">
                  <el-input
                    type="number"
                    readonly
                    v-model="baseInfo.quantity"
                  ></el-input>
                </el-form-item>
                <el-form-item label="价格区间">
                  <el-col :span="11">
                    <el-input
                      readonly
                      type="number"
                      v-model="baseInfo.startPrice"
                    ></el-input>
                  </el-col>
                  <el-col class="line" style="text-align: center" :span="2"
                    >-</el-col
                  >
                  <el-col :span="11">
                    <el-input
                      type="number"
                      readonly
                      v-model="baseInfo.endPrice"
                    ></el-input>
                  </el-col>
                </el-form-item>

                <el-form-item label="商品描述" style="width: 100%">
                  <el-input
                    type="textarea"
                    readonly
                    v-model="baseInfo.itemDescription"
                  ></el-input>
                </el-form-item>
                <el-form-item label="图片" style="width: 100%">
                  <div class="displayFlex">
                    <el-image
                      v-for="(item, index) in baseInfo.pics"
                      :key="index"
                      :src="item.picUrl"
                      @click="showBigimg(item.picUrl)"
                      style="width: 80px; height: 80px; margin-left: 20px"
                    ></el-image>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <!-- 跟进记录 -->
          <el-tab-pane label="跟进记录" name="second">
            <div class="btnList1" ref="gjBtnList">
              <div class="btnList1">
                <el-button class="itemBtn btnColor" @click="addBtn"
                  >新增跟进</el-button
                >
                <el-button class="itemBtn btnColor" @click="editBtn"
                  >编辑</el-button
                >
                <el-button class="itemBtn btnColor" @click="delBtn"
                  >删除</el-button
                >
              </div>
            </div>
            <el-table
              :data="oplogData"
              border
              :height="tableHeigth_log"
              stripe
              size="small"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                fixed="left"
                type="selection"
                width="60"
              ></el-table-column>
              <el-table-column
                prop="expertbuyStatusShow"
                align="center"
                label="状态"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="followUserName"
                align="center"
                label="跟进人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="followDescription"
                align="center"
                :show-overflow-tooltip="true"
                label="跟进描述"
                min-width="200"
              >
              </el-table-column>
              <el-table-column
                prop="followTime"
                align="center"
                label="跟进时间"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="createTime"
                align="center"
                label="创建时间"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
            </el-table>
            <paging
              ref="pags"
              :pageNum="pageStart_log"
              :total="pageCount_log"
              :sizeList="sizeList"
              :size="pageTotal_log"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
            >
            </paging>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- 弹出层 -->
    <div>
      <!-- 新建编辑跟进 -->
      <el-dialog
        :title="genjinTitle"
        :visible.sync="genJinShow"
        @close="closeDias"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-form
            ref="ruleForm"
            :rules="rules"
            :model="GenJinData"
            label-width="100px"
          >
            <el-form-item label="状态" prop="expertbuyStatus">
              <el-select
                class="queryInput"
                v-model="GenJinData.expertbuyStatus"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in orderTypeList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="跟进时间" prop="followTime">
              <el-date-picker
                style="width: 100%"
                v-model="GenJinData.followTime"
                clearable
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="跟进描述" prop="followDescription">
              <el-input
                type="textarea"
                clearable
                v-model="GenJinData.followDescription"
              ></el-input>
            </el-form-item>
            <el-form-item label="图片">
              <el-upload
                :action="uploadUrl"
                :http-request="uploadSectionImg"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileListImg"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmBtn('ruleForm')"
            >保 存</el-button
          >
        </div>
      </el-dialog>

      <!-- 图片预览 -->
      <el-dialog
        close-on-click-modal
        width="60%"
        :visible.sync="showBigImg"
        append-to-body
      >
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>

    <addCustomerTarck
      :showDia1="showDia1"
      :documentNumbers="documentNumbers"
      :documentStatus="documentStatus"
      :isdisabledStatus="true"
      @confirmAdd="confirmAdd"
      @closeDia1="closeDia1"
    ></addCustomerTarck>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";
import bigimg from "@/components/bigImg.vue";
import addCustomerTarck from "@/components/CustomerServiceTrackingNew.vue";

export default {
  components: { paging, bigimg, addCustomerTarck },
  data() {
    return {
      uploadUrl: this.common.uploadUrl,
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: "更多条件", //  输入框绑定动态class
      queryData: {
        memberAgentId: "", //归属代理商id
        expertBuyNumber: "", //工单号
        dgOrderNumber: "", //代购订单号
        createStartTime: "", //起始创建时间
        createEndTime: "", //结束创建时间
        memberId: "", //会员号
        memberName: "", //会员名称
        expertbuyStatus: "", //状态
      },
      channelList: [], //  渠道代理下拉列表
      storageList: [], //  仓库下拉列表
      kuaidiList: [], //快递公司下拉
      packageStatusList: [], //  包裹状态下拉列表
      testStatusList: [], //  验货状态下拉列表
      rejectionStatusList: [], //  拒签状态下拉列表
      expressData: [], //  快递单号表格
      tableHeigth: 0, //  表格高度
      tableHeigth_log: 0,
      pageStart: 1, //  分页配置
      pageTotal: 50,
      pageCount: 0,
      sizeList: [10, 20, 50, 100, 500],
      //    会员选择
      isDT: false,
      vf: {},
      vfr: {},
      ut: [],
      pageStart_u: 1, //  分页配置
      pageTotal_u: 50,
      pageCount_u: 0,
      //    查询轨迹
      isDT_query: false,
      eidtQuery: {
        courierNumber: "",
        expressCode: "",
        phone: "",
      }, //   查询表单
      formQuery: {},
      //    tab标签页
      activeName: "first",
      packageDetail: {},
      oplogData: [], //  操作日志表格数据
      pageStart_log: 1,
      pageTotal_log: 50,
      pageCount_log: 0,
      //    客户加收
      isDT_addC: false,
      customerForm: {
        settlementAmount: "",
        financeDocumentstatusId: "",
        customerAgentId: "",
        itemServiceId: "",
        comment: "",
        documentNumber: "",
        storageId: "",
        serviceName: "",
      },
      customerRules: {
        settlementAmount: [{ required: true, message: " ", trigger: "blur" }],
        userName: [{ required: true, message: " ", trigger: "blur" }],
        financeDocumentstatusId: [
          { required: true, message: " ", trigger: "change" },
        ],
        customerAgentId: [{ required: true, message: " ", trigger: "change" }],
      },
      lyArr: [],
      serveArr: [],
      isCustomer: true,
      agentArr: [],
      titleH: "",
      uids: "",
      orderTypeList: [], //代购状态
      orderFormList: [], //归属代理商
      memberList: [],
      currentSelectData: [],
      baseInfo: {},
      expertBuyId: "", //当前选择的专家购id
      dialogImageUrl: "", //大图
      showBigImg: false,
      genJinShow: false,
      genjinTitle: "新建跟进",
      openType: "",
      GenJinData: {
        expertbuyStatus: "",
        expertBuyApplyId: "",
        followDescription: "",
        followTime: "",
      },
      fileListImg: [],
      ImgNames: [],
      rules: {
        expertbuyStatus: [
          { required: true, message: "请选择", trigger: ["change", "blur"] },
        ],
        followTime: [
          { required: true, message: "请选择", trigger: ["change", "blur"] },
        ],
        followDescription: [
          { required: true, message: "请选择", trigger: ["change", "blur"] },
        ],
      },

      // 代购跟踪组件
      showDia1: false,
      documentNumbers: "",
      documentStatus: "",
    };
  },
  created() {
    this.getExpressData(); //  获取工单号

    this.getVipList();
    this.getStatusValList("expert_buy_apply_v1.expertbuy_status"); //专家购状态
  },
  mounted() {},
  activated() {
    this.expertBuyId = this.$route.query.expertBuyId || "";
    if (this.expertBuyId) {
      this.getDetails();
    }
  },
  watch: {},
  methods: {
    // 提交新建
    confirmAdd(formName) {
      this.showDia1 = false;
      this.getData();
    },
    // 关闭重置弹窗数据
    closeDia1(e) {
      this.showDia1 = e || false;
      this.documentNumbers = "";
    },

    kehugenzong() {
      if (this.baseInfo.expertBuyNumber) {
        this.documentNumbers = this.baseInfo.expertBuyNumber;
      }
      this.showDia1 = true;
      this.documentStatus = "dg_order_v1:document_status"; //代购单据
    },

    addBtn() {
      this.genjinTitle = "新建跟进";
      this.genJinShow = true;
      this.openType = "add";
    },
    editBtn() {
      if (this.currentSelectData.length != 1) {
        this.$message.warning("请选择一条记录跟进");
        return false;
      }

      Api.bmg_getExpertBuyRecordDetail({
        expertBuyFollowRecordId: this.currentSelectData[0].expertbuyFollowId,
      }).then((res) => {
        this.genjinTitle = "编辑";
        this.genJinShow = true;
        this.openType = "edit";
        this.GenJinData = res.data.result || {};
        let fileArr = [];
        let fileNameArr = [];
        if (this.GenJinData.fileVos && this.GenJinData.fileVos.length > 0)
          //
          this.GenJinData.fileVos.forEach((item) => {
            let obj = {
              url: item.fileUrl,
              name: item.fileId,
            };
            let names = {
              imgName: item.fileId,
            };
            fileArr.push(obj);
            fileNameArr.push(names);
          });
        this.fileListImg = fileArr;
        this.ImgNames = fileNameArr;
      });
    },
    // 删除跟进记录
    delBtn() {
      if (this.currentSelectData.length < 1) {
        this.$message.warning("请选择一条以上的记录进行删除");
        return false;
      }
      let ids = [];
      this.currentSelectData.forEach((item) => {
        ids.push(item.expertbuyFollowId);
      });
      this.$confirm("是否删除所选跟进记录?", "提示")
        .then(() => {
          let delparam = {
            expertBuyFollowRecordIds: ids,
          };
          delparam.sign = tools.getSign(delparam);
          Api.bmg_delExpertBuyRecord(delparam).then((res) => {
            this.$message.success(res.data.message || "删除成功");
            this.getExpertBuyRecord();
          });
        })
        .catch(() => {});
      //
    },
    closeDias() {
      this.ImgNames = [];
      this.fileListImg = [];
      this.GenJinData = {
        expertbuyStatus: "",
        expertBuyApplyId: "",
        followDescription: "",
        followTime: "",
      };
      this.$refs.ruleForm.resetFields();
    },
    // 保存新建/修改
    confirmBtn(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fileNameList = [];
          this.ImgNames.forEach((item) => {
            fileNameList.push(item.imgName);
          });

          if (that.openType == "add") {
            // 新建
            let params = {
              expertBuyApplyId: this.expertBuyId || "",
              expertbuyStatus: this.GenJinData.expertbuyStatus || "",
              fileNames: fileNameList,
              followDescription: this.GenJinData.followDescription || "",
              followTime: this.GenJinData.followTime || "",
            };
            params.sign = tools.getSign(params);
            Api.bmg_addExpertBuyRecord(params)
              .then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.genJinShow = false;
                this.getExpertBuyRecord();
              })
              .catch(() => {});
          } else {
            // 修改
            let editParam = {
              expertBuyFollowRecordId:
                this.GenJinData.expertBuyFollowRecordId || "",
              expertbuyStatus: this.GenJinData.expertbuyStatus || "",
              fileNames: fileNameList,
              followDescription: this.GenJinData.followDescription || "",
              followTime: this.GenJinData.followTime || "",
            };
            editParam.sign = tools.getSign(editParam);
            Api.bmg_updateExpertBuyRecord(editParam)
              .then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.genJinShow = false;
                this.getExpertBuyRecord();
              })
              .catch(() => {});
          }
        } else {
          return false;
        }
      });
    },

    uploadSectionImg(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let imgName = res.data.result.fileName;
            let objs = {
              imgName: imgName,
              oldName: fileName,
            };
            this.ImgNames.push(objs);
          }
        })
        .catch((err) => {});
    },
    //删除图片
    handleRemove(file, fileListImg) {
      this.ImgNames.forEach((item, index) => {
        if (item.oldName == file.name || item.imgName == file.name) {
          this.ImgNames.splice(index, 1);
          return;
        }
      });
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.showBigImg = true;
    },
    //查看大图
    showBigimg(url) {
      if (!url) {
        return false;
      }
      this.dialogImageUrl = url; //大图
      this.showBigImg = true;
    },
    // 切换tabs
    handleClickTabs(tab, event) {
      if (tab.name == "second") {
        this.getExpertBuyRecord();
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      ////console.log(e);
      this.currentSelectData = e || [];
    },
    //货区运快递公司
    expressChooseList() {
      Api.expressChooseList().then((res) => {
        if (res.data.status === "success") {
          this.kuaidiList = res.data.result || [];
        }
      });
    },
    getCangkuList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.storageList = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表`);
        }
      });
    },
    getChannelList() {
      //  获取渠道代理下拉列表
      Api.channelAgentChooseList().then((res) => {
        if (res.data.status === "success") {
          this.channelList = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--渠道代理下拉列表`);
        }
      });
    },
    // 获取状态展示值
    getStatusValList(status) {
      var dataArr = [];
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      // 专家购跟进状态:==========> expert_buy_apply_v1.expertbuy_status
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 代购状态
        if (status == "expert_buy_apply_v1.expertbuy_status") {
          let dataArr = res.data.result || [];
          this.orderTypeList = dataArr;
        }
      });
    },
    // 获取详情
    getDetails() {
      let param = {
        expertBuyId: this.expertBuyId,
      };
      Api.bmg_getExpertBuyDetail(param).then((res) => {
        if (res.data.status == "success") {
          this.baseInfo = res.data.result || {};
          this.queryData.expertBuyNumber = this.baseInfo.expertBuyNumber;
        }
      });
    },
    // 获取专家购跟进记录
    getExpertBuyRecord() {
      let param = {
        expertBuyId: this.expertBuyId,
      };
      Api.bmg_getExpertBuyRecord(param).then((res) => {
        if (res.data.status == "success") {
          this.oplogData = res.data.result.data || [];
          this.pageCount_log = res.data.result.pageCount || 0;
        }
      });
    },
    // 获取左边列表
    getExpressData() {
      let that = this;

      //   创建时间
      let createEndTime = "";
      let createStartTime = "";
      if (that.queryData.createEndTime) {
        createEndTime = that.queryData.createEndTime + " " + "23:59:59";
      }
      if (that.queryData.createStartTime) {
        createStartTime = that.queryData.createStartTime + " " + "00:00:00";
      }
      //   付款时间;
      let endPayTime = "";
      let startPayTime = "";
      if (that.queryData.endPayTime) {
        endPayTime = that.queryData.endPayTime + " " + "23:59:59";
      }
      if (that.queryData.startPayTime) {
        startPayTime = that.queryData.startPayTime + " " + "00:00:00";
      }

      let formData = {
        memberAgentId: that.queryData.memberAgentId, //归属代理商id
        expertBuyNumber: that.queryData.expertBuyNumber, //快递单号
        dgOrderNumber: that.queryData.dgOrderNumber, //代购订单号
        createStartTime: createStartTime, //起始创建时间
        createEndTime: createEndTime, //结束创建时间
        expertbuyStatus: that.queryData.expertbuyStatus, //状态
        memberId: that.queryData.memberId, //会员号
        memberName: that.queryData.memberName, //会员名称
        pageStart: that.pageNum, //页码
        pageTotal: that.size, //每页条数
      };

      Api.bmg_getExpertBuyList(formData).then((res) => {
        if (res.data.status == "success") {
          this.expressData = res.data.result.data;
          this.pageCount = res.data.result.pageCount || 0;
          this.fetTableHeight();

          if (!this.expertBuyId) {
            this.expertBuyId = this.expressData[0].expertBuyId;
            this.getDetails();
          }
        }
      });
    },

    eidtQuerySubmit() {
      this.queryForKD100();
    },
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
    },
    queryBtn_cz() {
      //  重置
      this.queryData = {};
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageStart = 1;
      this.getExpressData();
    },
    psc(val) {
      //  表格分页-当前页面总条数
      this.pageTotal = val;
      this.getExpressData();
    },
    pcc(val) {
      //  表格分页-页码
      this.pageStart = val;
      this.getExpressData();
    },
    dblclick(row) {
      this.expertBuyId = row.expertBuyId;
      if (this.expertBuyId) {
        this.getDetails();
      }
    },
    tableRowClassName(row, rowIndex) {
      if (row.row.expertBuyId == this.expertBuyId) {
        return "success-row";
      }
    },

    //   -------- 编辑会员 -----------

    getVipList() {
      //  获取会员列表
      this.vf.pageStart = this.pageStart_u;
      this.vf.pageTotal = this.pageTotal_u;
      Api.getMemberList(this.vf).then((res) => {
        if (res.data.status === "success") {
          this.ut = res.data.result.data;
          this.pageCount_u = res.data.result.pageCount || 0;
        }
      });
    },
    huiayu(e, num) {
      if (num == 1) {
        this.memberList.forEach((item) => {
          if (item.memberId == e) {
            this.queryData.memberName = item.nickname;
          }
        });
      }
      if (num == 2) {
        this.memberList.forEach((item) => {
          if (item.nickname == e) {
            this.queryData.memberId = item.memberId;
          }
        });
      }
      this.queryBtn_ok();
    },
    // 远程搜索会员
    getMembers(e) {
      if (e.length > 0) {
        this.getVipList(e);
      } else {
        this.getVipList();
      }
    },
    // 远程搜索会员名
    getMembersName(e) {
      if (e.length > 0) {
        this.getVipList(e, "name");
      } else {
        this.getVipList();
      }
    },
    getVipList(e, type) {
      let val = e || "";
      Api.getMemberDownList({
        memberId: type ? "" : val,
        nickname: type == "name" ? val : "",
      }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
          // this.total = res.data.result.pageCount || 0;
        }
      });
    },

    handleSizeChange(val) {
      this.pageTotal_log = val;
    },
    handleCurrentChange(val) {
      this.pageTotal_log = val;
    },

    //        ----------   计算表格高度    -------------

    resetHeight() {
      //  重置table高度
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        this.tableHeigth_log = 0;
        resolve();
      });
    },

    fetTableHeight() {
      // 设置table高度
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.tableHeigth_log = this.getHeight() - 120;
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },

    getHeight() {
      // 获取表格高度
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let btnHeight = this.$refs.btnList.offsetHeight || 40;
      let pagsHeigth = 60; //分页组件
      var otherHeight = queryHeigth + pagsHeigth + 60 + btnHeight;
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },
  },
};
</script>

<style scoped lang="scss">
.contentInner {
  width: 100%;
  display: flex;
  .rightInner {
    width: 200px;
    margin-right: 16px;
    cursor: pointer;
  }
  .leftInner {
    width: 80%;
    border: 1px solid #d7d7d7;
    /deep/ .el-tabs__item {
      height: 36px;
      line-height: 36px;
      background-color: #f4f4f4;
    }
    /deep/ .el-tabs__content {
      padding: 16px;
    }
    /deep/ .el-descriptions {
      margin-bottom: 10px;
    }

    /deep/ .is-top {
      width: 300px;
    }
  }
}

/deep/ .el-table .success-row {
  color: #f0a261 !important;
  background-color: #ffeab6 !important;
}

/*
    查询轨迹-查询表单
    */
.queryBox {
  margin-top: 20px;
}

.showBox {
  margin-left: 20px;
  .countryGrounp {
    display: flex;
    .countryItem {
      min-width: 100px;
      background-color: #cfcfcf;
      padding: 10px;
    }
    .number {
      min-width: 100px;
      background-color: #ababab;
      padding: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #fff;
    }
  }
}

.contentBox {
  width: 80%;
  margin-top: 20px;
}

.customerForm {
  .queryItem1 {
    margin: 0 auto;
    width: 60%;
    margin-bottom: 10px;
  }
}

.baseForm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  // .formItem {
  //   width: 30%;
  //   // min-width: 240px;
  // }
}
.displayFlex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>
