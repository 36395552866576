<template>
  <div>
    <!-- 新建 -->
    <el-dialog
      :title="diaTitle1"
      :visible.sync="showDia2"
      :modal-append-to-body="false"
      width="40%"
      center
      @close="closeDia1"
    >
      <div>
        <el-form
          label-position="right"
          label-width="80px"
          ref="addForm"
          :model="addFormDatas"
          :rules="rules1"
        >
          <el-form-item label="跟踪类型" prop="documentStatus">
            <el-select
              class="queryInput"
              v-model="addFormDatas.documentStatus"
              filterable
              placeholder="请选择"
              :disabled="isdisabledStatus"
            >
              <el-option
                v-for="item in documentStatusList"
                :key="item.statusVal"
                :label="item.showName"
                :value="item.statusVal"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联单据" prop="documentNumber">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="录入申请打包单号,多个单用逗号','隔开"
              v-model="addFormDatas.documentNumber"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述内容" prop="content">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入"
              v-model="addFormDatas.content"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入"
              v-model="addFormDatas.remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="发生时间" prop="operationTime">
            <el-date-picker
              style="width: 100%"
              v-model="addFormDatas.operationTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label=""
            v-if="addFormDatas.documentStatus == 'bill_pack_v1:document_status'"
          >
            <el-checkbox v-model="addFormDatas.isShow"
              >是否显示到运单轨迹</el-checkbox
            >
          </el-form-item>
          <el-form-item label="上传图片">
            <uploadimgs
              @input="changeFileList"
              :fileNameLists="fileNameLists"
              :limit="6"
            ></uploadimgs>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmAdd('addForm')"
          >提 交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import uploadimgs from "@/components/upLoadImgs.vue";
export default {
  components: { uploadimgs },
  props: {
    showDia1: {
      typeof: Boolean,
      default: false,
    },
    isdisabledStatus: {
      typeof: Boolean,
      default: false,
    },
    // isShowCheck: {
    //   typeof: Boolean,
    //   default: true,
    // },
    documentNumbers: {
      typeof: String,
      default: "", //关联单号
    },
    documentStatus: {
      typeof: String,
      default: "", //打包申请单
    },
  },
  watch: {
    showDia1: {
      //   immediate: true,
      handler: function (a, b) {
        this.showDia2 = a || false;
      },
      deep: true, //深度监听
    },
    documentStatus: {
      immediate: true,
      handler: function (a, b) {
        this.addFormDatas.documentStatus = a || "";
      },
      deep: true, //深度监听
    },

    documentNumbers: {
      immediate: true,
      handler: function (a, b) {
        this.addFormDatas.documentNumber = a || "";
      },
      deep: true, //深度监听
    },
  },
  data() {
    return {
      showDia2: false,
      documentStatusList: [],
      addFormDatas: {
        content: "",
        documentNumber: "",
        documentStatus: "",
        isShow: false,
        operationTime: "",
        remarks: "",
      },
      rules1: {
        documentNumber: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        content: [{ required: true, message: "请输入", trigger: "blur" }],
        operationTime: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        documentStatus: [
          { required: true, message: "请输入", trigger: "change" },
        ],
      },

      diaTitle1: "新建客服跟踪",
      fileNameLists: [],
      fileNames: [], //单文件名数组
    };
  },
  created() {
    this.getStatusValList("bill_kf_track_record_v2.document_status"); //客服跟踪单据类型
  },
  methods: {
    // 获取状态展示值
    getStatusValList(status) {
      var dataArr = [];
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 核销状态
        if (status == "bill_kf_track_record_v2.document_status") {
          let documentStatusList = res.data.result || [];
          this.documentStatusList = documentStatusList;
        }
      });
    },
    // 提交新建
    confirmAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datas = JSON.parse(JSON.stringify(this.addFormDatas));
          let dnArr = [];
          if (this.addFormDatas.documentNumber) {
            dnArr = tools.getOrderNum(this.addFormDatas.documentNumber) || [];
          }
          let fileNames = this.fileNameLists.map((item) => {
            return item.name;
          });
          datas.documentNumber = dnArr;
          datas.isShow = datas.isShow ? "1" : "0";
          datas.documentStatus = this.addFormDatas.documentStatus;
          datas.fileIds = fileNames || [];
          datas.sign = tools.getSign(datas);
          Api.addBillKfTrackRecordV2(datas).then((res) => {
            this.$message.success(res.data.message || "提交成功");
            this.$emit("confirmAdd", false);
          });
        } else {
        }
      });
    },
    // 关闭重置弹窗数据
    closeDia1() {
      // this.addFormDatas = {
      //   content: "",
      //   documentNumber: "",
      //   documentStatus: "",
      //   isShow: "",
      //   operationTime: "",
      //   remarks: "",
      // };
      this.addFormDatas.content = "";
      this.addFormDatas.documentNumber = "";
      this.addFormDatas.isShow = "";
      this.addFormDatas.operationTime = "";
      this.addFormDatas.remarks = "";
      this.showDia2 = false;
      this.$emit("closeDia1", false);
    },

    changeFileList(array) {
      this.fileNameLists = array;
      // this.fileNames = array.map((item) => {
      //   return item.fileName;
      // });
    },
  },
};

/***
 * 
 *  <addCustomerTarck
        :showDia1="showDia1"
        :documentNumbers="documentNumbers"
        :documentStatus="documentStatus"
        :isdisabledStatus="true"
        @confirmAdd="confirmAdd"
        @closeDia1="closeDia1"
      ></addCustomerTarck>
 * 
 * 使用流程
 * 1:引入组件 注册
 * import addCustomerTarck from "@/components/CustomerServiceTrackingNew.vue";
 * 注册 addCustomerTarck
 * 
 * 2:data里面定义
 *    showDia1: false,
      documentNumbers:"",
      documentStatus: "",
 * 3: 引入方法
     // 提交新建
    confirmAdd(formName) {
      this.showDia1 = false;
      this.getData();
    },
    // 关闭重置弹窗数据
    closeDia1(e) {
      this.showDia1 = e || false;
      this.documentNumbers ='';
    },
 * 
 * 
     


 */
</script>

<style>
</style>